import React from "react";
import ServiceCard from "./ServiceCard";
import ferrous from "../../photos/FerrousMaterial.PNG";
import nonferous from "../../photos/Non-ferrousMaterial.PNG";
import scrap from "../../photos/ScrapNon-Ferrouselectronicmaterial.jpg";
import precriousMetal from "../../photos/PreciousMetals.jpg";
import "./ServiceSections.css";

export const ServiceSections = () => {
  let materials = [
    {
      id: 1,
      material: "Ferrous Material",
      information: "HMS1, HMS2, Busheling, Rebar, P&S",
      photo: { ferrous },
    },
    {
      id: 2,
      material: "Non-Ferrous Material",
      information: "Aluminum (6061,6063,3xxx,5xxx,1xxx), Copper, Brass, Zinc, Lead, Tin",
      photo: { nonferous },
    },
    {
      id: 3,
      material: " Precious Non-Ferrous Electronic Material",
      information:
        "Cell Phones Boards, High Grade Circuit Boards, Gold Connectors, Gold Fingers, Telecommunication Boards, PCB Boards, all Precious Metal Bearing Materials",
      photo: { scrap },
    },
    {
      id: 4,
      material: "Precious Metals",
      information: "Gold, Silver, Platinum, Palladium, Rhodium",
      photo: { precriousMetal },
    },
  ];

  let onSite = [
    {
      id: 5,
      material: "Maquiladora Service",
      information:
        "Hazardous waste reclamation, transport, recycling, and disposal.",
    },
    // {
    //   id: 6,
    //   material: "Scrap Material Waste Services",
    //   information:
    //     "Collection, sorting, transport and processing of alloy-containing scrap materials.",
    // },
  ];

  return (
    <div className="service-content-section">
      <div className="service-content-border">
        <div id="service-section-one" className="service-heading">
          <h2>Materials We Specialize In</h2>
        </div>
        <div className="service-card-content">
          {materials.map((material) => {
            return (
              <div key={material.id} className="service-card-group">
                <ServiceCard data={material} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="service-content-border">
        <div className="service-header">
          <h2>Our Services</h2>
        </div>
        <div className="service-card-content">
          {onSite.map((material) => {
            return (
              <div key={material.id} className="onsite-card-group">
                <ServiceCard data={material} />
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="service-heading">
        <h2>Purchase and Sale</h2>
      </div>
      <div className="service-img-card-content">
        {materials.map((material) => {
          return (
            <div key={material.id} className="service-img-card-group">
              <ServiceCardVerionTwo data={material} />
            </div>
          );
        })}
      </div>
      <div className="service-heading">
        <h2>Our Services</h2>
      </div>
      <div className="service-img-card-content">
        {onSite.map((material) => {
          return (
            <div key={material.id} className="onsite-img-card-group">
              <ServiceCardVerionTwo data={material} />
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
