import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import HomePage from "./HomePage";
import { HashLink } from "react-router-hash-link";
import logo from "../photos/metalVentures.png";

const NavigationBar = () => {
  return (
    // <div>
    //   <Router>
    //     <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
    //       <Container>
    //         <Navbar.Brand href="/">
    //           <img src={logo} alt="company logo" width="30%" />
    //         </Navbar.Brand>
    //         <Nav className="me-auto">
    //           <Nav.Link as={Link} to={"/"}>
    //             Home
    //           </Nav.Link>
    //           <Nav.Link href="/services">About</Nav.Link>
    //           <Nav.Link href="/services">Services</Nav.Link>
    //           <Nav.Link href="/contact">Contact</Nav.Link>
    //         </Nav>
    //       </Container>
    //     </Navbar>
    //     <Routes>
    //       <Route path="/" element={<HomePage />} />
    //       <Route path="/services" element={<OurServices />} />
    //       <Route path="/contact" element={<Contact />} />
    //     </Routes>
    //   </Router>
    // </div>

    <div className="navbar">
      <Router>
        <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
          {/* <Container> */}
          <Navbar.Brand href="#">
            {" "}
            <img
              src={logo}
              alt="company logo"
              width="auto"
              height="40"
              className="d-inline-block align-top"
              style={{ marginLeft: "1rem" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto">
              <Nav.Link href="/#">Home</Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#about">
                About
              </Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#services">
                Services
              </Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#contact">
                Contact
              </Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#location">
                Location
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {/* </Container> */}
        </Navbar>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default NavigationBar; //react-router-hash-link
