import React from "react";
import "./HomeHeader.css";
import logo from "../../photos/metalVentures.png";

//metalventures\src\photos\metalVentures.png

const HomeHeader = () => {
  return (
    <div>
      <div className="header-main-section">
        <div className="header-background">
          <div className="header-textbox">
            <div className="heading-primary">
              <img src={logo} alt="company logo" />

              <span className="heading-primary-main">
                Scrap metal recycling for a cleaner tomorrow. 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
