import React from "react";
import HomeHeader from "../Components/homeComponent/HomeHeader";
import { ServiceSections } from "../Components/ServicesComponent/ServiceSections";
import ContactForms from "../Components/ContactComponent/ContactForms";
import "./HomePage.css";
import gmv from "../photos/metalVentureslogo.png";

const HomePage = () => {
  // let materials = [
  //   {
  //     id: 1,
  //     material: "Ferrous Material",
  //     information:
  //       "HMS1, HMS2, Busheling, Rebar, Stainless Steel(304,316), Plate &Structure",
  //     photo: { ferrousMaterial },
  //   },
  //   {
  //     id: 2,
  //     material: "Non-Ferrous Material",
  //     information: "Aluminum, Copper, Brass, Zinc, Lead, Tin",
  //   },
  //   {
  //     id: 3,
  //     material: "Scrap Non-Ferrous electronic material",
  //     information:
  //       "Cell Phones Boards, High Grade Circuit Boards, Gold Connectors, Gold Finger Boards, Telecommunication Boards, Miscellaneous Boards, non-ferrous high connectivity precious metal bearing material",
  //   },
  //   {
  //     id: 4,
  //     material: "Precious Metals",
  //     information: "Gold, Silver, Platinum, Palladium, Rhodium",
  //   },
  // ];

  // let onSite = [
  //   {
  //     id: 1,
  //     service: "Maquiladora Client Services",
  //     information:
  //       "Hazardous waste reclamation, transport, recycling, and disposal.",
  //   },
  //   {
  //     id: 2,
  //     service: "Scrap Material Waste Services",
  //     information:
  //       "Collection, sorting, transport and processing of alloy-containing scrap materials.",
  //   },
  // ];

  return (
    <div className="content">
      <div className="home-header-section"></div>
      <HomeHeader />
      <div>
        <div id="about" className="home-about-section">
          {" "}
          <h2>About Us</h2>
          <div className="about-content">
            <div className="about-content-left">
              <div className="desc">
                Based in the United States, Global Metal Ventures stands as a
                trailblazer in the metal trading industry, where innovation
                seamlessly integrates with robust partnerships. Since our
                establishment in 2018, our mission has been to redefine industry
                standards and make a lasting impact. Our reach extends beyond
                national boundaries, covering both the domestic and
                international markets, including significant engagement in
                Mexico.
              </div>
              <div className="desc">
                As metal traders, our global and domestic presence is fortified
                by strategic partnerships that are integral to our success,
                contributing significantly to our impact both locally and
                abroad.
              </div>
            </div>
            <div className="about-content-right">
              <img src={gmv} alt="gmv logo" />
              <p>Global Metal Ventures</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="home-sevices-section" id="services">
        <ServiceSections />
      </div>
      <div className="contact-section" id="contact">
        <div className="contact-form">
          <div className="contact-form-left">
            <h2>Contact Us</h2>
            <h6>Our Processing Location:</h6>
            <p>512 E. Pine Street, Compton, CA 90222</p>
            <span>
              <h6>Phone Number:</h6>
              <p>(310) 404-6362</p>
            </span>
            <p>
              For general inquiries about any of our operations in Mexico or the
              United States, please contact by using the form on this page. We’d
              love to hear from you!
            </p>
          </div>
          <div className="contact-form-right">
            <ContactForms />
          </div>
        </div>
      </div>
      <div className="map-section" id="location">
        <h2>Find Us on the Map</h2>
        <div className="map-content">
          <iframe
            title="Global Metal Ventures"
            width="100%"
            height="350"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=512%20E.%20Pine%20Street,%20Compton,%20CA%2090222+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
      <div className="footer">
        <span>&copy;</span> 2024 Global Metal Ventures, Inc.
      </div>
    </div>
  );
};

export default HomePage;
