import React from "react";
import ferrous from "../../photos/ferrous_2.jpg";
import nonferous from "../../photos/non-ferrous_3.jpg";
import scrap from "../../photos/ScrapNon-Ferrouselectronicmaterial.jpg";
import precriousMetal from "../../photos/PreciousMetals.jpg";
import maquiladora from "../../photos/Maquiladora.jpg";
import scraptruck from "../../photos/scraptruck.jpg";

import "./ServiceCard.css";

const ServiceCard = (props) => {
  const selectPhoto = (id) => {
    if (id === 1) {
      return ferrous;
    } else if (id === 2) {
      return nonferous;
    } else if (id === 3) {
      return scrap;
    } else if (id === 4) {
      return precriousMetal;
    } else if (id === 5) {
      return maquiladora;
    } else if (id === 6) {
      return scraptruck;
    }
  };

  return (
    <div>
      <div className="service-card">
        <div className="service-card-img">
          <img src={selectPhoto(props.data.id)} alt="metal material" />
        </div>
        <div className="service-card-title">
          <h4>{props.data.material} </h4>
        </div>
        <div className="service-card-desc">{props.data.information}</div>
      </div>
    </div>
  );
};

export default ServiceCard;
